
.paper-detail {
    height: 100%;
    padding-bottom: 100px;
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 40px;
        padding-bottom: 20px;
        font-size: 25px;
        text-align: center;
        border-bottom: 1px solid #eee;
        .back {
            position: absolute;
            left: 30px;
            top: 0;
        }
    }
    .scroll-content {
        height: 100%;
        overflow: hidden;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
